import de from '~/locales/de.json'
import en from '~/locales/en.json'
import fr from '~/locales/fr.json'

// TODO LANGUAGE Un-comment when we deploy those languages
// import es from '~/locales/es.json'
// import it from '~/locales/it.json'
// import pt from '~/locales/pt.json'

export default {
  fallbackLocale: 'en',
  legacy: false,
  locale: 'en',
  messages: {
    en,
    fr,
    de,
    // TODO LANGUAGE Un-comment when we deploy those languages
    // es,
    // pt,
    // it,
  },
}
